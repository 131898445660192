$pulseSize: 4em;
$pulseTiming: 1.2s;

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  z-index: 100;
  position: fixed; // Ensures loader is always fullscreen
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent; // Light overlay
}

.pulseLoader {
  width: $pulseSize;
  height: $pulseSize;
  border-radius: 50%; // Creates a circular shape
  background-color: black; // Change as needed
  animation: pulseanim $pulseTiming ease-in-out infinite;
}

@keyframes pulseanim {
  0% {
    transform: scale(0.1);
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
